/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      countAll: 0,
   },
   getters: {},
   actions: {
      getData({ commit, rootState }, param) {
         rootState.isLoading = true
         axios.post(`${baseURL}logs/table`, param).then(
            response => {
               commit('SET_DATA', response.data.rows)
               commit('SET_COUNT', response.data.totalRecords)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
   },
}
