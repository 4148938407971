import Vue from 'vue'
import numeral from 'numeral'

numeral.register('locale', 'id', {
   delimiters: {
      thousands: '.',
      decimal: ',',
   },
   abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
   },
   ordinal(number) {
      return number === 1 ? 'er' : 'ème'
   },
   currency: {
      symbol: 'Rp',
   },
})
numeral.locale('id')
Vue.filter('formatNumber', value => numeral(value).format('0,0'))
Vue.filter('formatRupiah', value => numeral(value).format('$ 0,0'))
