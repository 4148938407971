export default [
   {
      path: '/simbg/pbg',
      name: 'simbg-pbg',
      component: () => import('@/views/simbg/Pbg.vue'),
      meta: {
         pageTitle: 'SIMBG PBG',
         breadcrumb: [
            {
               text: 'SIMBG',
               active: false,
            },
            {
               text: 'PBG',
               active: true,
            },
         ],
      },
   },
   {
      path: '/simbg/slf',
      name: 'simbg-slf',
      component: () => import('@/views/simbg/Slf.vue'),
      meta: {
         pageTitle: 'SIMBG SLF',
         breadcrumb: [
            {
               text: 'SIMBG',
               active: false,
            },
            {
               text: 'SLF',
               active: true,
            },
         ],
      },
   },
   {
      path: '/simbg/imb',
      name: 'simbg-imb',
      component: () => import('@/views/simbg/Imb.vue'),
      meta: {
         pageTitle: 'SIMBG IMB',
         breadcrumb: [
            {
               text: 'SIMBG',
               active: false,
            },
            {
               text: 'IMB',
               active: true,
            },
         ],
      },
   },
   {
      path: '/simbg/scrap/pbg',
      name: 'simbg-scrap-pbg',
      component: () => import('@/views/simbg/ScrapPbg.vue'),
      meta: {
         pageTitle: 'SIMBG PBG Scrap',
         breadcrumb: [
            {
               text: 'SIMBG',
               active: false,
            },
            {
               text: 'PBG',
               active: true,
            },
         ],
      },
   },
   {
      path: '/simbg/scrap/slf',
      name: 'simbg-scrap-slf',
      component: () => import('@/views/simbg/ScrapSlf.vue'),
      meta: {
         pageTitle: 'SIMBG SLF Scrap',
         breadcrumb: [
            {
               text: 'SIMBG',
               active: false,
            },
            {
               text: 'SLF',
               active: true,
            },
         ],
      },
   },
]
