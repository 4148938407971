/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      itemsProses: [],
      item: {},
      itemProfile: {},
      countAll: 0,
      idIzin: 0,
   },
   getters: {},
   actions: {
      getData({ commit, rootState }, param) {
         rootState.isLoading = true
         axios.post(`${baseURL}simbg/pbg/table`, param).then(
            response => {
               commit('SET_DATA', response.data.rows)
               commit('SET_COUNT', response.data.totalRecords)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },
      getItem({ commit, rootState }, idIzin) {
         rootState.isLoading = true
         axios.get(`${baseURL}simbg/pbg/${idIzin}`).then(
            response => {
               commit('SET_ITEM', response.data.item)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },
      cetakIzin({ rootState }, id) {
         rootState.isLoading = true
         return axios
            .get(`${baseURL}simbg/pbg/cetak/${id}`, {
               responseType: 'blob',
            })
            .then(
               response => {
                  // commit("SET_IZIN_LAMA", response.data.items);
                  rootState.isLoading = false
                  return Promise.resolve(response)
               },
               error => {
                  rootState.isLoading = false
                  return Promise.reject(error)
               },
            )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_DATA_PROSES(state, data) {
         state.itemsProses = data
      },
      SET_ITEM(state, data) {
         state.item = data
      },
      SET_ITEM_PROFILE(state, data) {
         state.itemProfile = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
      SET_ID_IZIN(state, data) {
         state.idIzin = data
      },
   },
}
