/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      itemsNib: [],
      itemsIzinLama: [],
      item: {},
      countAll: 0,
      idProyek: 0,
   },
   getters: {},
   actions: {
      async getData({ commit, rootState }, param) {
         rootState.isLoading = true
         await axios.post(`${baseURL}oss/bidang_usaha/table`, param).then(
            response => {
               commit('SET_DATA', response.data.rows)
               commit('SET_COUNT', response.data.totalRecords)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },

      getItemsNib({ commit, rootState }, idPermohonan) {
         rootState.isLoading = true
         axios.get(`${baseURL}oss/bidang_usaha/nib/${idPermohonan}`).then(
            response => {
               commit('SET_ITEMS_NIB', response.data.items)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },

      getItem({ commit, rootState }, idProyek) {
         rootState.isLoading = true
         axios.get(`${baseURL}oss/bidang_usaha/${idProyek}`).then(
            response => {
               commit('SET_ITEM', response.data.item)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },

      getIzinLama({ commit, rootState }, idProyek) {
         rootState.isLoading = true
         axios.get(`${baseURL}oss/bidang_usaha/izin_lama/${idProyek}`).then(
            response => {
               commit('SET_IZIN_LAMA', response.data.items)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },

      cetakIzinLama({ rootState }, idPermohonanIzin) {
         rootState.isLoading = true
         return axios
            .get(
               `${baseURL}oss/bidang_usaha/izin_lama_cetak/${idPermohonanIzin}`,
               { responseType: 'blob' },
            )
            .then(
               response => {
                  // commit("SET_IZIN_LAMA", response.data.items);
                  rootState.isLoading = false
                  return Promise.resolve(response)
               },
               error => {
                  rootState.isLoading = false
                  return Promise.reject(error)
               },
            )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_ITEMS_NIB(state, data) {
         state.itemsNib = data
      },
      SET_ITEM(state, data) {
         state.item = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
      SET_ID_PROYEK(state, data) {
         state.idProyek = data
      },
      SET_IZIN_LAMA(state, data) {
         state.itemsIzinLama = data
      },
   },
}
