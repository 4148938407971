import axios from 'axios'
// import config from '@/data/config'

const API_URL = process.env.VUE_APP_BASE_URL

class AuthService {
   login = user => axios
      .post(`${API_URL}auth/login`, {
         username: user.username,
         password: user.password,
      })
      .then(response => {
         localStorage.setItem('userData', JSON.stringify(response.data))
         return response.data
      });

   register = user => axios.post(`${API_URL}auth/signup`, {
      username: user.username,
      email: user.email,
      password: user.password,
   });

   logout = () => {
      axios.post(`${API_URL}auth/logout`).then(() => {
         localStorage.removeItem('userData')
         return true
      })
   };
}

export default new AuthService()
