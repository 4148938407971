export default [
   {
      path: '/izin_manual/siup',
      name: 'im-siup',
      component: () => import('@/views/manual/Siup.vue'),
      meta: {
         pageTitle: 'SIUP',
         breadcrumb: [
            {
               text: 'Izin Manual',
               active: false,
            },
            {
               text: 'SIUP',
               active: true,
            },
         ],
      },
   },
]
