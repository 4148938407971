/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      item: {},
      itemProfile: {},
      countAll: 0,
   },
   getters: {},
   actions: {
      getData({ commit, rootState }, param) {
         rootState.isLoading = true
         axios.post(`${baseURL}oss/nib/table`, param).then(
            response => {
               commit('SET_DATA', response.data.rows)
               commit('SET_COUNT', response.data.totalRecords)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },
      getItem({ commit, rootState }, idPermohonan) {
         rootState.isLoading = true
         return axios.get(`${baseURL}oss/nib/${idPermohonan}`).then(
            response => {
               commit('SET_ITEM', response.data.item)

               rootState.isLoading = false
               return response.data.item
            },
            () => {
               rootState.isLoading = false
            },
         )
      },

      getProfileItem({ commit, rootState }, idProfile) {
         rootState.isLoading = true
         axios.get(`${baseURL}oss/nib/profile/${idProfile}`).then(
            response => {
               commit('SET_ITEM_PROFILE', response.data.item)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },

      cetakNib({ rootState }, nib) {
         rootState.isLoading = true
         return axios
            .get(`${baseURL}oss/nib/cetak/${nib}`, {
               responseType: 'blob',
            })
            .then(
               response => {
                  // commit("SET_IZIN_LAMA", response.data.items);
                  rootState.isLoading = false
                  return Promise.resolve(response)
               },
               error => {
                  rootState.isLoading = false
                  return Promise.reject(error)
               },
            )
      },
      cetakReport({ rootState }, nib) {
         rootState.isLoading = true
         return axios
            .post(
               `${baseURL}oss/laporan/nib/${nib}`,
               {},
               {
                  responseType: 'blob',
               },
            )
            .then(
               response => {
                  // commit("SET_IZIN_LAMA", response.data.items);
                  rootState.isLoading = false
                  return Promise.resolve(response)
               },
               error => {
                  rootState.isLoading = false
                  return Promise.reject(error)
               },
            )
      },
      cetakLaporan({ rootState }, tanggal) {
         rootState.isLoading = true
         return axios.post(`${baseURL}oss/laporan/harian`, { tanggal }, { responseType: 'blob' }).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response)
            },
            error => {
               rootState.isLoading = false
               return Promise.reject(error)
            },
         )
      },
      cetakLaporanTriwulan({ rootState }, payload) {
         rootState.isLoading = true
         return axios.post(`${baseURL}oss/laporan/triwulan`, payload, { responseType: 'blob' }).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response)
            },
            error => {
               rootState.isLoading = false
               return Promise.reject(error)
            },
         )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_ITEM(state, data) {
         state.item = data
      },
      SET_ITEM_PROFILE(state, data) {
         state.itemProfile = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
   },
}
