export default [
   {
      path: '/sicantik/permohonan',
      name: 'sicantik-permohonan',
      component: () => import('@/views/sicantik/Permohonan.vue'),
      meta: {
         pageTitle: 'SICANTIK',
         breadcrumb: [
            {
               text: 'Sicantik',
               active: false,
            },
            {
               text: 'Permohonan',
               active: true,
            },
         ],
      },
   },
   {
      path: '/sicantik/scrap',
      name: 'sicantik-scrap',
      component: () => import('@/views/sicantik/Scrap.vue'),
      meta: {
         pageTitle: 'Permohonan Scrap',
         breadcrumb: [
            {
               text: 'Sicantik',
               active: false,
            },
            {
               text: 'Scrap',
               active: true,
            },
         ],
      },
   },
   {
      path: '/sicantik/scrap-detail',
      name: 'sicantik-scrap-detail',
      component: () => import('@/views/sicantik/ScrapDetail.vue'),
      meta: {
         pageTitle: 'Permohonan Scrap Detail',
         breadcrumb: [
            {
               text: 'Sicantik',
               active: false,
            },
            {
               text: 'Scrap',
               active: true,
            },
         ],
      },
   },
]
