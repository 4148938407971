/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      data: [],
      countAll: 0,
      current: 0,
      success: 0,
      failed: 0,
      runningState: false,
      cancelToken: '',
   },
   getters: {},
   actions: {
      async getData({ commit, rootState }) {
         rootState.isLoading = true
         await axios.get(`${baseURL}sicantik/scrap`).then(
            response => {
               commit('SET_DATA', response.data.items)
               commit('SET_COUNT', response.data.items)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },
      async runScrap({ commit, rootState, state }, idPermohonan) {
         rootState.isLoading = true
         const param = { id_permohonan: idPermohonan }
         await axios
            .post(`${baseURL}sicantik/scrap/download`, param, {
               cancelToken: state.cancelToken,
            })
            .then(
               () => {
                  commit('SET_SUCCESS')
                  commit('NEXT')
                  rootState.isLoading = false
                  return Promise.resolve()
               },
               () => {
                  commit('SET_FAILED')
                  commit('NEXT')
                  rootState.isLoading = false
                  return Promise.resolve()
               },
            )
      },
      async runScrapPermohonan({ rootState, state }) {
         rootState.isLoading = true
         await axios
            .post(
               `${baseURL}sicantik/scrap/permohonan`,
               {},
               {
                  cancelToken: state.cancelToken,
               },
            )
            .then(
               () => {
                  rootState.isLoading = false
                  return Promise.resolve()
               },
               () => {
                  rootState.isLoading = false
                  return Promise.reject()
               },
            )
      },
      async loginSicantik({ rootState, state }) {
         rootState.isLoading = true
         await axios
            .post(
               `${baseURL}sicantik/scrap/login`,
               {},
               {
                  cancelToken: state.cancelToken,
               },
            )
            .then(
               () => {
                  rootState.isLoading = false
                  return Promise.resolve()
               },
               () => {
                  rootState.isLoading = false
                  return Promise.reject()
               },
            )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.data = data
      },
      SET_DATA_TABLE(state, data) {
         state.items = data
      },
      SET_COUNT(state, data) {
         state.countAll = data.length
      },
      SET_COUNT_TABLE(state, data) {
         state.countAll = data
      },
      SET_CURRENT(state, data) {
         state.current = data
      },
      SET_SUCCESS(state) {
         state.success += 1
      },
      SET_FAILED(state) {
         state.failed += 1
      },
      SET_STATE(state, data) {
         state.runningState = data
      },
      SET_CANCEL_TOKEN(state, data) {
         state.cancelToken = data
      },
      NEXT(state) {
         state.current += 1
      },
   },
}
