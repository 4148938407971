/* eslint-disable no-param-reassign */
import AuthService from '@/services/authService'

const user = JSON.parse(localStorage.getItem('userData'))
const initialState = user
   ? { status: { loggedIn: true }, user }
   : { status: { loggedIn: false }, user: null }

export default {
   namespaced: true,
   state: initialState,
   actions: {
      login({ commit, rootState }, data) {
         rootState.isLoading = true
         return AuthService.login(data).then(
            response => {
               rootState.isLoading = false
               commit('loginSuccess', response)
               return Promise.resolve(response)
            },
            error => {
               rootState.isLoading = false
               commit('loginFailure')
               return Promise.reject(error)
            },
         )
      },
      logout({ commit }) {
         AuthService.logout()
         commit('logout')
      },
      register({ commit }, data) {
         return AuthService.register(data).then(
            response => {
               commit('registerSuccess')
               return Promise.resolve(response.data)
            },
            error => {
               commit('registerFailure')
               return Promise.reject(error)
            },
         )
      },
   },
   mutations: {
      loginSuccess(state, data) {
         state.status.loggedIn = true
         state.user = data
      },
      loginFailure(state) {
         state.status.loggedIn = false
         state.user = null
      },
      logout(state) {
         state.status.loggedIn = false
         state.user = null
      },
      registerSuccess(state) {
         state.status.loggedIn = false
      },
      registerFailure(state) {
         state.status.loggedIn = false
      },
   },
}
