import Vue from 'vue'
import { BootstrapVue, ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import vueMoment from 'vue-moment'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import '@core/scss/vue/libs/vue-good-table.scss'
import TextHighlight from 'vue-text-highlight'
// import * as VeeValidate from 'vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueCurrencyInput from 'vue-currency-input'
import Datepicker from 'vuejs-datepicker'
// import { id } from 'vuejs-datepicker/dist/locale'
import VueToast from 'vue-toastification'

// BSV Plugin Registration
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Vue.use(VeeValidate, {
//    inject: true,
//    fieldsBagName: 'veeFields',
//    errorBagName: 'veeErrors',
// })
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('text-highlight', TextHighlight)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.use(VueSweetalert2)
moment.locale('id')

Vue.use(vueMoment, { moment })

Vue.use(VueCurrencyInput, {
   globalOptions: { currency: 'IDR', locale: 'id-ID' },
})

Vue.component('Datepicker', Datepicker)
// Vue.use(Datepicker, {
//    language: id,
// })

Vue.use(VueToast, {
   // One of the options
   position: 'bottom-right',
})
