/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
   },
   actions: {
      getData({ commit, rootState }) {
         rootState.isLoading = true
         return axios.get(`${baseURL}pengaturan`).then(
            response => {
               commit('SET_DATA', response.data)
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      saveData({ rootState }, data) {
         rootState.isLoading = true
         return axios.post(`${baseURL}pengaturan`, data).then(
            () => {
               rootState.isLoading = false
               return Promise.resolve()
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
   },
}
