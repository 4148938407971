/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      item: {},
      countAll: 0,
   },
   getters: {},
   actions: {
      getData({ commit, rootState }, param) {
         rootState.isLoading = true
         axios.post(`${baseURL}oss/kbli/table`, param).then(
            response => {
               commit('SET_DATA', response.data.rows)
               commit('SET_COUNT', response.data.totalRecords)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },
      getItem({ commit, rootState }, idPermohonan) {
         rootState.isLoading = true
         return axios.get(`${baseURL}oss/kbli/${idPermohonan}`).then(
            response => {
               rootState.isLoading = false
               commit('SET_ITEM', response.data.item)
               return Promise.resolve(response.data.item)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      postItem({ rootState }, data) {
         rootState.isLoading = true
         return axios.post(`${baseURL}oss/kbli`, data).then(
            () => {
               rootState.isLoading = false
               return Promise.resolve()
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      updateItem({ rootState }, data) {
         rootState.isLoading = true
         return axios.put(`${baseURL}oss/kbli/${data.kode}`, data).then(
            () => {
               rootState.isLoading = false
               return Promise.resolve()
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      deleteItem({ rootState }, kode) {
         rootState.isLoading = true
         return axios.delete(`${baseURL}oss/kbli/${kode}`).then(
            () => {
               rootState.isLoading = false
               return Promise.resolve()
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_ITEM(state, data) {
         state.item = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
   },
}
