import Vue from 'vue'
import VueRouter from 'vue-router'
import oss from './routes/oss'
import sicantik from './routes/sicantik'
import simbg from './routes/simbg'
import izinManual from './routes/izinManual'

Vue.use(VueRouter)

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   scrollBehavior() {
      return { x: 0, y: 0 }
   },
   routes: [
      {
         path: '/',
         name: 'dashboard',
         component: () => import('@/views/dashboard/index.vue'),
         meta: {
            resource: 'dashboard',
            action: 'read',
            pageTitle: 'Dashboard',
            breadcrumb: [
               {
                  text: 'Dashboard',
                  active: true,
               },
            ],
         },
      },
      ...oss,
      ...sicantik,
      ...simbg,
      ...izinManual,
      {
         path: '/lkpm',
         name: 'lkpm',
         component: () => import('@/views/lkpm/Lkpm.vue'),
         meta: {
            resource: 'lkpm',
            action: 'manage',
            pageTitle: 'LKPM',
            breadcrumb: [
               {
                  text: 'LKPM',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/lkpm/scrap/run',
         name: 'lkpm-scrap-run',
         component: () => import('@/views/lkpm/Scrap.vue'),
         meta: {
            pageTitle: 'LKPM Scrap',
            breadcrumb: [
               {
                  text: 'LKPM Scrap',
                  active: false,
               },
               {
                  text: 'Run',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/pengaduan',
         name: 'pengaduan',
         component: () => import('@/views/pengaduan/Data.vue'),
         meta: {
            resource: 'pengaduan',
            action: 'manage',
            pageTitle: 'Pengaduan',
            breadcrumb: [
               {
                  text: 'Pengaduan',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/skm-hasil',
         name: 'skm-hasil',
         component: () => import('@/views/skm/Hasil.vue'),
         meta: {
            resource: 'skm',
            action: 'read',
            pageTitle: 'SKM',
            breadcrumb: [
               {
                  text: 'SKM',
                  active: false,
               },
               {
                  text: 'DPMPTSP',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/skm-hasil-mpp',
         name: 'skm-hasil-mpp',
         component: () => import('@/views/skm/HasilMpp.vue'),
         meta: {
            resource: 'skm',
            action: 'read',
            pageTitle: 'SKM MPP',
            breadcrumb: [
               {
                  text: 'SKM',
                  active: false,
               },
               {
                  text: 'MPP',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/pengaturan',
         name: 'pengaturan',
         component: () => import('@/views/Pengaturan.vue'),
         meta: {
            resource: 'pengaturan',
            action: 'manage',
            pageTitle: 'Pengaturan',
            breadcrumb: [
               {
                  text: 'Pengaturan',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/pengguna',
         name: 'pengguna',
         component: () => import('@/views/User.vue'),
         meta: {
            resource: 'pengguna',
            action: 'manage',
            pageTitle: 'Pengguna',
            breadcrumb: [
               {
                  text: 'Pengguna',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/pengguna-role',
         name: 'pengguna-role',
         component: () => import('@/views/Role.vue'),
         meta: {
            resource: 'pengguna',
            action: 'manage',
            pageTitle: 'Role',
            breadcrumb: [
               {
                  text: 'Role',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/pengguna-aktivitas',
         name: 'pengguna-aktivitas',
         component: () => import('@/views/Aktivitas.vue'),
         meta: {
            resource: 'pengguna',
            action: 'manage',
            pageTitle: 'Aktivitas',
            breadcrumb: [
               {
                  text: 'Aktivitas',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/logs',
         name: 'logs',
         component: () => import('@/views/Logs.vue'),
         meta: {
            resource: 'log',
            action: 'manage',
            pageTitle: 'Logs',
            breadcrumb: [
               {
                  text: 'Logs',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/profile',
         name: 'profile',
         component: () => import('@/views/profile/Index.vue'),
         meta: {
            pageTitle: 'Profile',
            breadcrumb: [
               {
                  text: 'Profile',
                  active: true,
               },
            ],
         },
      },
      {
         path: '/login',
         name: 'login',
         component: () => import('@/views/Login.vue'),
         meta: {
            layout: 'full',
         },
      },
      {
         path: '/error-404',
         name: 'error-404',
         component: () => import('@/views/error/Error404.vue'),
         meta: {
            layout: 'full',
         },
      },
      {
         path: '*',
         redirect: 'error-404',
      },
   ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
   // Remove initial loading
   const appLoading = document.getElementById('loading-bg')
   if (appLoading) {
      appLoading.style.display = 'none'
   }
})

export default router
