/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      data: [],
      countAll: 0,
      current: 0,
      success: 0,
      failed: 0,
      runningState: false,
      cancelToken: '',
   },
   getters: {},
   actions: {
      async getData({ commit, rootState }) {
         rootState.isLoading = true
         await axios.get(`${baseURL}oss/scrap/izin_lama`).then(
            response => {
               commit('SET_DATA', response.data.items)
               commit('SET_COUNT', response.data.items)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },
      async runScrap({ commit, rootState, state }, param) {
         rootState.isLoading = true

         await axios
            .post(`${baseURL}oss/scrap/download/izin_lama`, param, {
               cancelToken: state.cancelToken,
            })
            .then(
               () => {
                  commit('SET_SUCCESS')
                  commit('NEXT')
                  rootState.isLoading = false
               },
               () => {
                  commit('SET_FAILED')
                  commit('NEXT')
                  rootState.isLoading = false
               },
            )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.data = data
      },
      SET_COUNT(state, data) {
         state.countAll = data.length
      },
      SET_CURRENT(state, data) {
         state.current = data
      },
      SET_SUCCESS(state) {
         state.success += 1
      },
      SET_FAILED(state) {
         state.failed += 1
      },
      SET_STATE(state, data) {
         state.runningState = data
      },
      SET_CANCEL_TOKEN(state, data) {
         state.cancelToken = data
      },
      NEXT(state) {
         state.current += 1
      },
   },
}
