/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      itemsProses: [],
      item: {},
      itemProfile: {},
      countAll: 0,
      idIzin: 0,
   },
   getters: {},
   actions: {
      getData({ commit, rootState }) {
         rootState.isLoading = true
         axios.get(`${baseURL}skm/hasil`).then(
            response => {
               commit('SET_DATA', response.data.rows)
               commit('SET_COUNT', response.data.totalRecords)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },
      importData({ rootState }, file) {
         rootState.isLoading = true

         const formData = new FormData()
         formData.append('file', file, file.name)

         return axios
            .post(`${baseURL}skm/hasil/import`, formData, {
               headers: {
                  'Content-Type': 'multipart/form-data',
               },
            })
            .then(
               () => {
                  rootState.isLoading = false
                  return Promise.resolve()
               },
               () => {
                  rootState.isLoading = false
                  return Promise.reject()
               },
            )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_DATA_PROSES(state, data) {
         state.itemsProses = data
      },
      SET_ITEM(state, data) {
         state.item = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
      SET_ID_IZIN(state, data) {
         state.idIzin = data
      },
   },
}
