/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      item: {},
      countAll: 0,
   },
   getters: {},
   actions: {
      getSiup({ commit, rootState }, filter) {
         rootState.isLoading = true
         return axios.post(`${baseURL}izin_manual/siup`, { filter }).then(
            response => {
               commit('SET_DATA', response.data.rows)
               commit('SET_COUNT', response.data.totalRecords)
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_ITEM(state, data) {
         state.item = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
   },
}
