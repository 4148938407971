import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './modules/app'
import appConfig from './modules/app-config'
import verticalMenu from './modules/vertical-menu'
import Authentication from './modules/authentication'

// OSS
import Dashboard from './modules/dashboard'
import OssNib from './modules/oss/nib'
import OssBidangUsaha from './modules/oss/bidangUsaha'
import OssIzinRba from './modules/oss/izinRba'
import OssKbli from './modules/oss/kbli'
import OssScrap from './modules/oss/scrap'
import OssScrapIzin from './modules/oss/scrapIzin'
import OssScrapRepairIzinRba from './modules/oss/scrapRepairIzinRba'
import OssScrapRepairIzinLama from './modules/oss/scrapRepairIzinLama'
import OssScrapTerbitIzinLama from './modules/oss/scrapTerbitIzinLama'
import OssScrapDownloadIzin from './modules/oss/scrapDownloadIzin'
import OssScrapDownloadNib from './modules/oss/scrapDownloadNib'

// SICANTIK
import SicantikPermohonan from './modules/sicantik/permohonan'
import SicantikScrap from './modules/sicantik/scrap'
import SicantikScrapDetail from './modules/sicantik/scrapDetail'

// SIMBG
import SimbgPbg from './modules/simbg/pbg'
import SimbgSlf from './modules/simbg/slf'
import SimbgImb from './modules/simbg/imb'
import SimbgScrapPbg from './modules/simbg/scrapPbg'
import SimbgScrapSlf from './modules/simbg/scrapSlf'

// SKM
import SkmHasil from './modules/skm/hasil'
import SkmHasilMpp from './modules/skm/hasil-mpp'
// Pengaduan
import Pengaduan from './modules/pengaduan/data'
// LKPM
import Lkpm from './modules/lkpm/lkpm'
import LkpmScrap from './modules/lkpm/scrap'
// Izin Manual
import IzinManual from './modules/izinManual'
// Pengaduan
import Pengaturan from './modules/pengaturan'
// USER
import User from './modules/user'
import Role from './modules/role'
import Logs from './modules/logs'
import Profile from './modules/profile'

Vue.use(Vuex)

export default new Vuex.Store({
   state: {
      isLoading: false,
   },
   modules: {
      app,
      appConfig,
      verticalMenu,
      Authentication,
      Dashboard,

      OssNib,
      OssBidangUsaha,
      OssIzinRba,
      OssKbli,
      OssScrap,
      OssScrapIzin,
      OssScrapRepairIzinRba,
      OssScrapRepairIzinLama,
      OssScrapTerbitIzinLama,
      OssScrapDownloadNib,
      OssScrapDownloadIzin,

      SicantikPermohonan,
      SicantikScrap,
      SicantikScrapDetail,

      SimbgPbg,
      SimbgSlf,
      SimbgImb,
      SimbgScrapPbg,
      SimbgScrapSlf,

      Lkpm,
      LkpmScrap,

      Pengaduan,
      SkmHasil,
      SkmHasilMpp,
      IzinManual,

      Pengaturan,

      User,
      Role,
      Logs,
      Profile,
   },
   strict: process.env.DEV,
})
