export default [
   {
      path: '/oss/nib',
      name: 'oss-nib',
      component: () => import('@/views/oss/Nib.vue'),
      meta: {
         pageTitle: 'OSS NIB',
         breadcrumb: [
            {
               text: 'OSS',
               active: false,
            },
            {
               text: 'NIB',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/nib/:id',
      name: 'oss-nib-detail',
      component: () => import('@/views/oss/NibDetail.vue'),
      meta: {
         pageTitle: 'OSS NIB Detail',
         breadcrumb: [
            {
               text: 'OSS',
               active: false,
            },
            {
               text: 'NIB',
               active: false,
               path: '/oss/nib',
            },
            {
               text: 'Detail',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/bidang-usaha',
      name: 'oss-bidang-usaha',
      component: () => import('@/views/oss/BidangUsaha.vue'),
      meta: {
         pageTitle: 'OSS Bidang Usaha',
         breadcrumb: [
            {
               text: 'OSS',
               active: false,
            },
            {
               text: 'Bidang Usaha',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/perizinan-rba',
      name: 'oss-perizinan-rba',
      component: () => import('@/views/oss/IzinRba.vue'),
      meta: {
         pageTitle: 'OSS Perizinan RBA',
         breadcrumb: [
            {
               text: 'OSS',
               active: false,
            },
            {
               text: 'Perizinan RBA',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/kbli',
      name: 'oss-kbli',
      component: () => import('@/views/oss/Kbli.vue'),
      meta: {
         pageTitle: 'OSS KBLI',
         breadcrumb: [
            {
               text: 'OSS',
               active: false,
            },
            {
               text: 'KBLI',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/laporan',
      name: 'oss-laporan',
      component: () => import('@/views/oss/Laporan.vue'),
      meta: {
         pageTitle: 'OSS Laporan',
         breadcrumb: [
            {
               text: 'OSS',
               active: false,
            },
            {
               text: 'Laporan',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/scrap',
      name: 'oss-scrap',
      component: () => import('@/views/oss/ScrapSource.vue'),
      meta: {
         pageTitle: 'OSS Scrap Source',
         breadcrumb: [
            {
               text: 'OSS Scrap Source',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/scrap/run',
      name: 'oss-scrap-run',
      component: () => import('@/views/oss/Scrap.vue'),
      meta: {
         pageTitle: 'OSS Scrap',
         breadcrumb: [
            {
               text: 'OSS Scrap',
               active: false,
            },
            {
               text: 'Run',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/scrap/izin',
      name: 'oss-scrap-izin',
      component: () => import('@/views/oss/ScrapIzin.vue'),
      meta: {
         pageTitle: 'OSS Scrap Izin',
         breadcrumb: [
            {
               text: 'OSS Scrap Izin',
               active: false,
            },
            {
               text: 'Run',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/scrap/terbit_izin_lama',
      name: 'oss-scrap-terbit-izin-lama',
      component: () => import('@/views/oss/ScrapTerbitIzinLama.vue'),
      meta: {
         pageTitle: 'Terbit Izin Lama',
         breadcrumb: [
            {
               text: 'OSS Scrap',
               active: false,
            },
            {
               text: 'Terbit Izin Lama',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/scrap/repair_izin_lama',
      name: 'oss-scrap-repair-izin-lama',
      component: () => import('@/views/oss/ScrapRepairIzinLama.vue'),
      meta: {
         pageTitle: 'Repair Izin Lama',
         breadcrumb: [
            {
               text: 'OSS Scrap',
               active: false,
            },
            {
               text: 'Repair Izin Lama',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/scrap/repair_izin_rba',
      name: 'oss-scrap-repair-izin-rba',
      component: () => import('@/views/oss/ScrapRepairIzinRba.vue'),
      meta: {
         pageTitle: 'Repair Izin RBA',
         breadcrumb: [
            {
               text: 'OSS Scrap',
               active: false,
            },
            {
               text: 'Repair Izin RBA',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/scrap/download_izin',
      name: 'oss-scrap-download-izin',
      component: () => import('@/views/oss/ScrapDownloadIzin.vue'),
      meta: {
         pageTitle: 'Download Izin',
         breadcrumb: [
            {
               text: 'OSS Scrap',
               active: false,
            },
            {
               text: 'Download Izin',
               active: true,
            },
         ],
      },
   },
   {
      path: '/oss/scrap/download_nib',
      name: 'oss-scrap-download-nib',
      component: () => import('@/views/oss/ScrapDownloadNib.vue'),
      meta: {
         pageTitle: 'Download NIB',
         breadcrumb: [
            {
               text: 'OSS Scrap',
               active: false,
            },
            {
               text: 'Download NIB',
               active: true,
            },
         ],
      },
   },
]
