/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      item: {},
      countAll: 0,
      total: 0,
   },
   getters: {},
   actions: {
      getData({ commit, rootState }, params) {
         rootState.isLoading = true
         return axios
            .get(`${baseURL}lkpm`, {
               params,
            })
            .then(
               response => {
                  commit('SET_DATA', response.data.rows)
                  commit('SET_COUNT', response.data.totalRecords)
                  commit('SET_TOTAL', response.data.total)
                  rootState.isLoading = false
                  return Promise.resolve(response.data)
               },
               () => {
                  rootState.isLoading = false
                  return Promise.reject()
               },
            )
      },
      getItem({ commit, rootState }, id) {
         rootState.isLoading = true
         return axios.get(`${baseURL}lkpm/${id}`).then(
            response => {
               rootState.isLoading = false
               commit('SET_ITEM', response.data.item)
               return Promise.resolve(response.data.item)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      postItem({ rootState }, data) {
         rootState.isLoading = true
         return axios.post(`${baseURL}lkpm`, data).then(
            () => {
               rootState.isLoading = false
               return Promise.resolve()
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      updateItem({ rootState }, data) {
         rootState.isLoading = true
         return axios.put(`${baseURL}lkpm/${data.id}`, data).then(
            () => {
               rootState.isLoading = false
               return Promise.resolve()
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      deleteItem({ rootState }, id) {
         rootState.isLoading = true
         return axios.delete(`${baseURL}lkpm/${id}`).then(
            () => {
               rootState.isLoading = false
               return Promise.resolve()
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      cetakNib({ rootState }, id) {
         rootState.isLoading = true
         return axios
            .get(`${baseURL}lkpm/cetak/${id}`, {
               responseType: 'blob',
            })
            .then(
               response => {
                  // commit("SET_IZIN_LAMA", response.data.items);
                  rootState.isLoading = false
                  return Promise.resolve(response)
               },
               error => {
                  rootState.isLoading = false
                  return Promise.reject(error)
               },
            )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_ITEM(state, data) {
         state.item = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
      SET_TOTAL(state, data) {
         state.total = data
      },
   },
}
