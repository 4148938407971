/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      item: {},
      itemProfile: {},
      countAll: 0,
   },
   getters: {},
   actions: {
      getData({ commit, rootState }, param) {
         rootState.isLoading = true
         axios.post(`${baseURL}oss/izin_rba/table`, param).then(
            response => {
               commit('SET_DATA', response.data.rows)
               commit('SET_COUNT', response.data.totalRecords)
               rootState.isLoading = false
            },
            () => {
               rootState.isLoading = false
            },
         )
      },
      cetakIzin({ rootState }, idPermohonanIzin) {
         rootState.isLoading = true
         return axios
            .get(
               `${baseURL}oss/izin_rba/cetak/${idPermohonanIzin}`,
               { responseType: 'blob' },
            )
            .then(
               response => {
                  // commit("SET_IZIN_LAMA", response.data.items);
                  rootState.isLoading = false
                  return Promise.resolve(response)
               },
               error => {
                  rootState.isLoading = false
                  return Promise.reject(error)
               },
            )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_ITEM(state, data) {
         state.item = data
      },
      SET_ITEM_PROFILE(state, data) {
         state.itemProfile = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
   },
}
