import Vue from 'vue'

import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios'

import router from './router'
import store from './store'
import App from './App.vue'

// import authHeader from './services/authHeader'

// Global Components
import './global-components'
// eslint-disable-next-line import/extensions
import './global-filters'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

axios.interceptors.request.use(config => {
   try {
      if (localStorage.getItem('userData')) {
         const { token } = JSON.parse(localStorage.getItem('userData'))
         if (token) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${token}`
         }
      }
   } catch (error) {
      console.error(error)
   }
   return config
})

axios.interceptors.response.use(
   response => response,
   error => {
      try {
         // console.log(`onError => ${JSON.stringify(error)}`)
         // console.log(error.response.data.message)
         const msg = error.response.data.message || error.response.data || error.response
         Vue.$toast.error(JSON.stringify(msg))
      } catch (e) {
         // console.log('onError.raw', e)
         Vue.$toast.error(JSON.stringify(error.response.request.statusText))
      }

      if (error.response.status === 401) {
         localStorage.removeItem('user')

         if (error.response.config.url !== `${process.env.VUE_APP_BASE_URL}auth/login`) {
            router.push('/login')
         }

         return Promise.reject(error)
      }
      if (error.response.status === 403) {
         Vue.$toast.error('Tidak diizinkan')
         router.push('/')

         return Promise.reject(error)
      }

      return Promise.reject(error)
   },
)

new Vue({
   router,
   store,
   render: h => h(App),
}).$mount('#app')
