/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      roles: [],
      aktivitas: [],
      item: {},
      countAll: 0,
      total: 0,
   },
   getters: {},
   actions: {
      getData({ commit, rootState }, params) {
         rootState.isLoading = true
         return axios
            .get(`${baseURL}user`, {
               params,
            })
            .then(
               response => {
                  commit('SET_DATA', response.data.rows)
                  commit('SET_COUNT', response.data.totalRecords)
                  rootState.isLoading = false
                  return Promise.resolve(response.data)
               },
               () => {
                  rootState.isLoading = false
                  return Promise.reject()
               },
            )
      },
      getAktivitas({ commit, rootState }, params) {
         rootState.isLoading = true
         return axios
            .get(`${baseURL}user/aktivitas`, {
               params,
            })
            .then(
               response => {
                  commit('SET_DATA_AKTIVITAS', response.data)
                  rootState.isLoading = false
                  return Promise.resolve(response.data)
               },
               () => {
                  rootState.isLoading = false
                  return Promise.reject()
               },
            )
      },
      getRoles({ commit, rootState }, params) {
         rootState.isLoading = true
         return axios
            .get(`${baseURL}user/roles`, {
               params,
            })
            .then(
               response => {
                  commit('SET_ROLE', response.data)
                  rootState.isLoading = false
                  return Promise.resolve(response.data)
               },
               () => {
                  rootState.isLoading = false
                  return Promise.reject()
               },
            )
      },
      getItem({ commit, rootState }, id) {
         rootState.isLoading = true
         return axios.get(`${baseURL}user/${id}`).then(
            response => {
               rootState.isLoading = false
               commit('SET_ITEM', response.data.item)
               return Promise.resolve(response.data.item)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      postItem({ rootState }, data) {
         rootState.isLoading = true
         return axios.post(`${baseURL}user`, data).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      resetPassword({ rootState }, id) {
         rootState.isLoading = true
         return axios.put(`${baseURL}user/reset/${id}`).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      updateItem({ rootState }, data) {
         rootState.isLoading = true
         return axios.put(`${baseURL}user/${data.id}`, data).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      deleteItem({ rootState }, id) {
         rootState.isLoading = true
         return axios.delete(`${baseURL}user/${id}`).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      logoutItem({ rootState }, id) {
         rootState.isLoading = true
         return axios.delete(`${baseURL}user/logout/${id}`).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      logoutAll({ rootState }, data) {
         rootState.isLoading = true
         return axios.post(`${baseURL}user/logoutall`, data).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_DATA_AKTIVITAS(state, data) {
         state.aktivitas = data
      },
      SET_ROLE(state, data) {
         state.roles = data
      },
      SET_ITEM(state, data) {
         state.item = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
   },
}
