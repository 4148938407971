/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      user: {},
      loginInfo: {},
      log: [],
   },
   getters: {},
   actions: {
      getData({ commit, rootState }, params) {
         rootState.isLoading = true
         return axios
            .get(`${baseURL}profile`, {
               params,
            })
            .then(
               response => {
                  commit('SET_DATA', response.data)
                  rootState.isLoading = false
                  return Promise.resolve(response.data)
               },
               () => {
                  rootState.isLoading = false
                  return Promise.reject()
               },
            )
      },
      changePassword({ rootState }, data) {
         rootState.isLoading = true
         return axios.post(`${baseURL}profile/change_password`, data).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },

   },
   mutations: {
      SET_DATA(state, data) {
         state.user = data.user
         state.log = data.log
         state.loginInfo = data.loginInfo
      },
   },
}
