/* eslint-disable no-param-reassign */
import axios from 'axios'
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   namespaced: true,
   state: {
      items: [],
      resources: [],
      item: {},
      countAll: 0,
      total: 0,
   },
   getters: {},
   actions: {
      getData({ commit, rootState }, params) {
         rootState.isLoading = true
         return axios
            .get(`${baseURL}role`, {
               params,
            })
            .then(
               response => {
                  commit('SET_DATA', response.data.rows)
                  commit('SET_COUNT', response.data.totalRecords)
                  rootState.isLoading = false
                  return Promise.resolve(response.data)
               },
               () => {
                  rootState.isLoading = false
                  return Promise.reject()
               },
            )
      },
      getResources({ commit, rootState }, params) {
         rootState.isLoading = true
         return axios
            .get(`${baseURL}role/resources`, {
               params,
            })
            .then(
               response => {
                  commit('SET_RESOURCES', response.data)
                  rootState.isLoading = false
                  return Promise.resolve(response.data)
               },
               () => {
                  rootState.isLoading = false
                  return Promise.reject()
               },
            )
      },
      getItem({ commit, rootState }, id) {
         rootState.isLoading = true
         return axios.get(`${baseURL}role/${id}`).then(
            response => {
               rootState.isLoading = false
               commit('SET_ITEM', response.data.item)
               return Promise.resolve(response.data.item)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      postItem({ rootState }, data) {
         rootState.isLoading = true
         return axios.post(`${baseURL}role`, data).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      updateItem({ rootState }, data) {
         rootState.isLoading = true
         return axios.put(`${baseURL}role/${data.id}`, data).then(
            response => {
               rootState.isLoading = false
               return Promise.resolve(response.data)
            },
            () => {
               rootState.isLoading = false
               return Promise.reject()
            },
         )
      },
      deleteItem({ rootState }, id) {
         rootState.isLoading = true
         return axios
            .delete(`${baseURL}role/${id}`)
            .then(
               response => {
                  rootState.isLoading = false
                  return Promise.resolve(response.data)
               },
               response => {
                  rootState.isLoading = false
                  return Promise.reject(response.data)
               },
            )
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data
      },
      SET_RESOURCES(state, data) {
         state.resources = data
      },
      SET_ITEM(state, data) {
         state.item = data
      },
      SET_COUNT(state, data) {
         state.countAll = data
      },
   },
}
